export function urlParamsToObj(value) {
    if (!value) {
        return {};
    }

    return value
        .replace(/[?]/g, '')
        .split('&')
        .map(param => {
            const [key, value] = param.split('=');
            return { [key]: value };
        })
        .reduce((acc, param) => {
            return {
                ...acc,
                ...param
            };
        }, {});
}

export function objToUrlParams(value, addDelimiter = true) {
    if (!value) {
        return '';
    }
    
    const delimiter = addDelimiter ? '?' : '';

    const queryString = Object.keys(value)
        .map(key => `${key}=${value[key]}`)
        .join('&');

    return `${delimiter}${queryString}`;
}

