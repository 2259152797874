export function isDone() {
    if (window.location.hostname === 'localhost') {
        return Promise.resolve();
    }

    return new Promise((resolve) => {
        if (window.AngularEcommerce && window.AngularEcommerce.DudaWidgets) {
            window.AngularEcommerce.DudaWidgets.onReady().then(() => resolve());
        } else {
            window.addEventListener('duda-widgets:ready', () => resolve());
        }
    });
}