import './templates';

import * as components from './components';

window.AngularEcommerce = window.AngularEcommerce || {};
window.AngularEcommerce['race-bootcamp'] = {
    ...components
};



function seCdcInUrl() {

    const timer = setTimeout(() => {
        const auth = window.AngularEcommerce.web.authToken;
        if (!auth) {
            seCdcInUrl();
            return;
        }
        if (window.location.pathname.indexOf('horarios') !== -1) {
            return;
        }
        const token = auth.get();
        const cdc = {
            isCrossDomainCookie: true,
            action: 'GET',
            payload: token,
        };
        if (!token) {
            return;
        }
        const hostname = window.location.hostname;
        const race = document.querySelector('#race_sso');
        const tonus = document.querySelector('#tonus_sso');
        const vidya = document.querySelector('#vidya_sso');
        const jab = document.querySelector('#jab_sso');

        if (race && hostname != 'racebootcamp.com.br') {
            race.href = `${race.href}${cdc ? '?cdc=' + window.btoa(JSON.stringify(cdc)) : ''}`;
        }
        if (tonus && hostname != 'tonusgym.com.br') {
            tonus.href = `${tonus.href}${cdc ? '?cdc=' + window.btoa(JSON.stringify(cdc)) : ''}`;
        }

        if (vidya && hostname != 'vidyastudio.com.br') {
            vidya.href = `${vidya.href}${cdc ? '?cdc=' + window.btoa(JSON.stringify(cdc)) : ''}`;
        }

        if (jab && hostname != 'jabhouse.com.br') {
            jab.href = `${jab.href}${cdc ? '?cdc=' + window.btoa(JSON.stringify(cdc)) : ''}`;
        }

        //Desktop
        const logoRace = document.querySelector('.logo_topo_race');
        const logoTonus = document.querySelector('.logo_topo_tonus');
        const logoVidya = document.querySelector('.logo_topo_vidya');
        const logoJab = document.querySelector('.logo_topo_jab');
        if (logoRace && hostname != 'racebootcamp.com.br') {
            logoRace.href = `${logoRace.href}${cdc ? '?cdc=' + window.btoa(JSON.stringify(cdc)) : ''}`;
        }

        if (logoTonus && hostname != 'tonusgym.com.br') {
            logoTonus.href = `${logoTonus.href}${cdc ? '?cdc=' + window.btoa(JSON.stringify(cdc)) : ''}`;
        }

        if (logoVidya && hostname != 'vidyastudio.com.br') {
            logoVidya.href = `${logoVidya.href}${cdc ? '?cdc=' + window.btoa(JSON.stringify(cdc)) : ''}`;
        }

        if (logoJab && hostname != 'jabhouse.com.br') {
            logoJab.href = `${logoJab.href}${cdc ? '?cdc=' + window.btoa(JSON.stringify(cdc)) : ''}`;
        }
        clearTimeout(timer);
    }, 3000);

}


seCdcInUrl();