var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12 col-sm-6 d-flex flex-column mb-3" }, [
      _c("span", {
        ref: "geturl",
        on: {
          click: function($event) {
            return _vm.getUrl()
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "unit-selector-label" }, [_vm._v("CIDADE")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.locationSelected,
              expression: "locationSelected"
            }
          ],
          staticClass: "unit-selector-select",
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.locationSelected = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function($event) {
                _vm.unitSelected = null
              }
            ]
          }
        },
        [
          _c(
            "option",
            { attrs: { disabled: "disabled" }, domProps: { value: null } },
            [_vm._v("Selecione uma opção")]
          ),
          _vm._v(" "),
          _vm._l(_vm.locations, function(item, key) {
            return [
              _c("option", { key: key, domProps: { value: item.id } }, [
                _vm._v(_vm._s(item.name))
              ])
            ]
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 col-sm-6 d-flex flex-column unit-selector-step" },
      [
        _vm.disableUnitStep
          ? [_c("div", { staticClass: "unit-selector-blocker" })]
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "unit-selector-label" }, [_vm._v("UNIDADE")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.unitSelected,
                expression: "unitSelected"
              }
            ],
            staticClass: "unit-selector-select",
            attrs: { disabled: _vm.disableUnitStep },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.unitSelected = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c(
              "option",
              { attrs: { disabled: "disabled" }, domProps: { value: null } },
              [_vm._v("Selecione uma opção")]
            ),
            _vm._v(" "),
            _vm._l(_vm.unitList, function(item, key) {
              return [
                _c("option", { key: key, domProps: { value: item.id } }, [
                  _vm._v(_vm._s(item.name))
                ])
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "custom-control custom-checkbox" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.useCache,
                expression: "useCache"
              }
            ],
            staticClass: "custom-control-input",
            attrs: {
              type: "checkbox",
              id: "remember-selection",
              disabled: _vm.disableUnitStep
            },
            domProps: {
              checked: Array.isArray(_vm.useCache)
                ? _vm._i(_vm.useCache, null) > -1
                : _vm.useCache
            },
            on: {
              change: function($event) {
                var $$a = _vm.useCache,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.useCache = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.useCache = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.useCache = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "custom-control-label",
              attrs: { for: "remember-selection" }
            },
            [_vm._v("Lembrar esta cidade/unidade")]
          )
        ])
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _vm.load
        ? _c(
            "p",
            {
              staticClass: "text-center mt-5",
              staticStyle: { "text-align": "center" }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "20",
                    viewBox: "0 0 38 38",
                    xmlns: "http://www.w3.org/2000/svg",
                    stroke: "#000"
                  }
                },
                [
                  _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "translate(1 1)",
                          "stroke-width": "2"
                        }
                      },
                      [
                        _c("circle", {
                          attrs: {
                            "stroke-opacity": ".5",
                            cx: "18",
                            cy: "18",
                            r: "18"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "path",
                          { attrs: { d: "M36 18c0-9.94-8.06-18-18-18" } },
                          [
                            _c("animateTransform", {
                              attrs: {
                                attributeName: "transform",
                                type: "rotate",
                                from: "0 18 18",
                                to: "360 18 18",
                                dur: "1s",
                                repeatCount: "indefinite"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }