<template>
  <div class="row">
    <div class="col-12 col-sm-6 d-flex flex-column">
      <span class="unit-selector-label">COMPRAR AULAS EM:</span>

      <select class="unit-selector-select" @change="posSelected=null;" v-model="locationSelected">
        <option :value="null" disabled="disabled">Selecione uma opção</option>
        <template v-for="(item, key) in locations">
          <option :value="item.id" :key="key">{{ item.name }}</option>
        </template>
      </select>
    </div>

    <div class="col-12 col-sm-6 d-flex flex-column unit-selector-step">
      <template v-if="disableUnitStep">
        <div class="unit-selector-blocker"></div>
      </template>

      <span class="unit-selector-label">UNIDADE:</span>

      <select class="unit-selector-select" v-model="posSelected" :disabled="disableUnitStep">
        <option :value="null" disabled="disabled">Selecione uma opção</option>
        <template v-for="(item, key) in posList">
          <option :value="item.id" :key="key">{{ item.name }}</option>
        </template>
      </select>

      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          id="remember-selection"
          v-model="useCache"
          :disabled="disableUnitStep"
        />
        <label class="custom-control-label" for="remember-selection"
          >Lembrar esta cidade/unidade</label
        >
      </div>
    </div>
    <div class="col-md-12">
      <p v-if="load" class="text-center mt-5" style="text-align: center">
        <svg
          width="20"
          height="20"
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#000"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </p>
    </div>
  </div>
</template>

<style lang="scss">
.unit-selector-hide {
  display: none;
}

.unit-selector-label {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.unit-selector-select {
  border: 1px solid #000 !important;
}

.unit-selector-step {
  position: relative;
}

.unit-selector-blocker {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  position: absolute;
  top: 0;
  background: rgba(245, 245, 245, 1);
  z-index: 50;
}

.title-widget-package{
  display: none;
}
</style>

<script>
import { objToUrlParams } from '../../functions/url-param';


class Cache {
  static get key() {
    return 'unit-package-selector';
  }

  static set(data) {
    return window.localStorage.setItem(
      this.key,
      JSON.stringify({
        ...this.get(),
        ...data,
      })
    );
  }

  static get() {
    return JSON.parse(window.localStorage.getItem(this.key) || '{}');
  }

  static has() {
    return Boolean(this.get());
  }
}

export default {
  props: ['packageWidgetSelector', 'params'],

  data() {
    return {
      posSelected: null,
      locationSelected: null,
      useCache: false,
      disableUnitStep: true,
      posList: [],
      locations: [],
      key:1,
      paramsUrl:null,
      load:false
    };
  },

  created() {
    this.hidePackages();

    this.getLocations().then((locations) => {
      this.locations = locations;

      const { rememberSelection, pos, location } = Cache.get();

      this.useCache = rememberSelection === true;

      if (this.useCache) {
        if (location) {
          this.locationSelected = Number(location);
        }

        if (pos) {
          this.posSelected = pos;
        }
      }
    });
  },
  mounted(){
    if(window.location.search != ''){
      let url = window.location.search;

      let objURL = this.getUrlVars(url);

      if(!this.locationSelected && objURL.location){
        this.locationSelected = objURL.location;
      }
      if(!this.posSelected && objURL.unit){
        this.posSelected = objURL.unit;
      }

    }
  },
  watch: {
    useCache(value) {
      Cache.set({ rememberSelection: value });
    },
    locationSelected(value) {
      this.disableUnitStep = false;
      Cache.set({ location: value });
      this.hidePackages();
      this.posList = [];
      this.getPOSList().then((results) => (this.posList = results));
      // if(this.key==1){
      //   this.key++;
      // }else{
      //   this.posSelected = null;
      // }
    },
    posSelected(value) {
      Cache.set({ pos: value });
      if(!value) return; 
      this.paramsUrl = {location: this.locationSelected, unit: this.posSelected};
      this.updateParamsUrl();
      this.hidePackages();
      this.load = true;
      this.setPOS().then(() => this.loadPackages());
    },
  },

  computed: {
    packageWidgetEl() {
      return document.querySelectorAll(this.packageWidgetSelector);
    },
  },

  methods: {
    getUrlVars(url) {
        var hash;
        var myJson = {};
        var hashes = url.slice(url.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            myJson[hash[0]] = hash[1];
        }
        return myJson;
    },
    hidePackages() {
      if(this.packageWidgetEl) this.packageWidgetEl.forEach(item=>{
        item.classList.add('unit-selector-hide');
      });
    },
    showPackages() {
      if(this.packageWidgetEl) this.packageWidgetEl.forEach(item=>{
        item.classList.remove('unit-selector-hide');
      });
      
    },
    loadPackages() {
      /* 
        Quando os dados vem de preseleção o componente de pacotes não foi carregado ainda.
        Interval permite rodar o código apenas quando o componente existir.
      */

      const interval = window.setInterval(() => {
        
        let packages = this.packageWidgetEl;

        if (packages) {
          window.clearInterval(interval);
          this.showPackages();
          packages.forEach((item, i)=>{
            
            item.querySelector('ae-package-list').posId = this.posSelected;

            let paramsFixed = {
              pos_list: this.posSelected,
              is_noshow_penalty: false,
              in_catalogue: true,
              active: true,
            };

            let paramsProps = {...this.params[i]}
            delete paramsProps.activityId

            item.querySelector('ae-package-list').params = {...paramsFixed, ...paramsProps};

            this.load = false;
            this.showPackages();

            let time = 0;

            let interval3 = setInterval(()=>{
              if(document.querySelectorAll('.ae-package-list').length==0){
                clearInterval(interval3);
                return;
              }
              if(document.querySelectorAll('.ae-package-list')[i].querySelector('.ae-package-container')){
                document.querySelectorAll('.title-widget-package')[i].style = 'display:block !important;';
              }else{
                if(document.querySelectorAll('.title-widget-package')[i]){
                  document.querySelectorAll('.title-widget-package')[i].style = 'display:none !important;';
                }
              }
              time ++;

              if(time >= 20) clearInterval(interval3); 
              //clearInterval(interval3)
            }, 500);

          });

        }

        return;

      }, 100);
    },
    getLocations() {
      return new Promise((resolve) => {
        const locations = [];

        const paginator = window.AngularEcommerce.ws.locations.getAll({
          params: {
            enabled: true,
            use_as_filter: true,
            activity_list: this.params[0].activityId,
          }
        });

        paginator.getAllPages((res) => {
          locations.push(...res.data.results);

          if (!paginator.hasNextPage()) {
            resolve(locations);
          }
        });
      });
    },
    getPOSList() {
      return new Promise((resolve) => {
        const posList = [];

        const paginator = window.AngularEcommerce.ws.pointsOfSale.getAll({
          params: {
            is_online: true,
            activity_list: this.params[0].activityId,
            location_list: this.locationSelected,
          },
        });

        paginator.getAllPages((res) => {
          posList.push(...res.data.results);

          if (!paginator.hasNextPage()) {
            resolve(posList);
          }
        });
      });
    },
    setPOS() {
      return window.AngularEcommerce.ws.checkout.get().then(({ data }) => {
        if (data.pos_id === this.posSelected) {
          return Promise.resolve();
        }

        return window.AngularEcommerce.ws.checkout.setPOS('current', {
          point_of_sale: this.posSelected,
        });
      });
    },
    updateParamsUrl(){
      let url = new URLSearchParams(window.location.search);
      url.delete('location');
      url.delete('unit');
      window.history.replaceState(
        {},
        undefined,
        `${window.location.pathname}${objToUrlParams(this.paramsUrl)}${url.toString()?'&'+url.toString():''}`
      );
    }
  },
};
</script>