/**
 * Esse arquivo não pode ser exportado e nem importar algo
 * pois vai conter o operador "with" que é proibido em strict-mode
 * causando assim erro no build, caso o babel detecte o mesmo
 */

window.AngularEcommerce = window.AngularEcommerce || {};
window.AngularEcommerce.web = window.AngularEcommerce.web || {};
window.AngularEcommerce.web.templates = {
    events: {
        /*
        aplique o template gerado no widget builder
        ex: evento: { render: ... }
        */
    },
    packages: {
        /*
        aplique o template gerado no widget builder
        ex: pacotes: { render: ... }
        */
    },
    instructors: {
        /*
        aplique o template gerado no widget builder
        ex: instrutor: { render: ... }
        */
    },
    checkoutItems: {
        /*
        aplique o template gerado no widget builder
        ex: itemCheckout: { render: ... }
        */
    }
};
